import type { GaVueComponent } from "@/common/vueUtils";
import { assert, assertIsDefined } from "@utils/assertion";

export type SelectOption<T> = {
  label: string;
  value: T;
};

export function GaFormFieldInputSelect<T>(props: { options: SelectOption<T>[]; label?: string; name: string; value: T; onInputValue: (value: T) => void }): GaVueComponent {
  function onInput(event: Event): void {
    assert(event.target instanceof HTMLSelectElement);
    const value = props.options[event.target.selectedIndex];
    assertIsDefined(value);
    props.onInputValue(value.value);
  }

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}
        </label>
      ) : null}
      <select class="form-control form-select" name={props.name} onInput={onInput}>
        {props.options.map((option) => (
          <option selected={props.value === option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
}
