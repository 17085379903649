import type { GaVueComponent } from "@/common/vueUtils";
import type { WithOptional } from "@utils/formUtils";

type Props = {
  checked: boolean;
  onUpdateChecked: (newValue: boolean) => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  switchStyle: boolean;
};

type PropsWithOptional = WithOptional<Props, "disabled" | "required" | "switchStyle">;

function addDefaults(props: PropsWithOptional): Props {
  return {
    ...props,
    disabled: props.disabled ?? false,
    required: props.required ?? false,
    switchStyle: props.switchStyle ?? false,
  };
}

export function GaFormFieldInputCheckbox(p: PropsWithOptional): GaVueComponent {
  const props: Props = addDefaults(p);

  return (
    <div class={props.switchStyle ? "form-check form-switch" : "form-check"}>
      <input
        type="checkbox"
        checked={props.checked}
        value="true"
        onInput={(e) => props.onUpdateChecked((e.target as HTMLInputElement).checked)}
        name={props.name}
        id={props.name}
        required={props.required}
        disabled={props.disabled}
        class={"form-check-input"}
      />
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class={"form-check-label"}>
          {props.label} {props.required ? <span>*</span> : null}
        </label>
      ) : null}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
