import type { GaVueComponent } from "@/common/vueUtils";
import type { WithOptional } from "@utils/formUtils";

type Variant = "text" | "email" | "password" | "search" | "number" | "date";

type Props = {
  type: Variant;
  value: string;
  onUpdateValue: (newValue: string) => void;
  onFocus?: () => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  placeholder: string;
};
type PropsWithOptional = WithOptional<Props, "type" | "disabled" | "required" | "placeholder">;

function addDefaults(props: PropsWithOptional): Props {
  return {
    ...props,
    type: props.type ?? "text",
    required: props.required ?? false,
    placeholder: props.placeholder ?? "",
    disabled: props.disabled ?? false,
  };
}

export function GaFormFieldInputText(p: PropsWithOptional): GaVueComponent {
  const props = addDefaults(p);

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label} {props.required ? <span>*</span> : null}
        </label>
      ) : null}
      <input
        onFocus={props.onFocus}
        type={props.type}
        value={props.value}
        onInput={(e) => props.onUpdateValue((e.target as HTMLInputElement).value)}
        name={props.name}
        placeholder={props.placeholder}
        id={props.name}
        required={props.required}
        disabled={props.disabled}
        class="form-control"
      />
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
