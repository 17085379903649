import { createParentComponent } from "@/common/vueUtils";
import uniqueId from "lodash/uniqueId";

type ChipBodyProps = {
  icon?: string;
  img?: string;
};

const ChipBody = createParentComponent<ChipBodyProps>((props) => (
  <>
    {props.icon !== undefined ? <i class={"chip-thumbnail-icon " + props.icon} aria-hidden="true"></i> : props.img !== undefined ? <img class="chip-thumbnail-image" src={props.img} alt="" /> : null}
    <span>{props.children}</span>
  </>
));

type Variant = "primary" | "danger" | "success";
type Type = "checkbox" | "radio" | "link" | "removable";
type GaChipProps = {
  type?: Type;
  href?: string;
  icon?: string;
  variant?: Variant;
  image?: string;
  active?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  value?: string;
  changeHandler?: () => void;
  border?: boolean;
};

export const GaChip = createParentComponent<GaChipProps>((props) => {
  const active = props.active ?? false;
  const disabled = props.disabled ?? false;
  const border = props.border ?? false;
  const id = props.id ?? uniqueId("chip-");
  const type = props.type ?? "";

  const chipClass = props.variant === "danger" ? "chip--danger" : props.variant === "success" ? "chip--success" : props.variant === "primary" ? "chip--primary" : "";

  const basicClasses = (): string[] => {
    const classes = ["chip", chipClass];
    if (border) {
      classes.push("chip--border");
    }
    if (["radio", "checkbox", "link"].includes(type)) {
      classes.push("chip--interactive");
    }

    return classes;
  };

  const basicClassList = (): string => {
    return basicClasses().join(" ");
  };

  const fullClassList = (): string => {
    const classes = basicClasses();
    if (active) {
      classes.push("active");
    }
    if (disabled) {
      classes.push("disabled");
    }
    return classes.join(" ");
  };

  const classListBody = (): string => {
    const classes = ["chip-body"];
    return classes.join(" ");
  };

  if (props.type === "checkbox" || props.type === "radio") {
    return (
      <span class={basicClassList()}>
        <input
          type={props.type}
          name={props.name}
          id={id}
          hidden="hidden"
          onClick={(e) => {
            if (props.changeHandler !== undefined) {
              e.preventDefault();
              props.changeHandler();
            }
          }}
          value={props.value}
          disabled={disabled}
          checked={props.active}
        />
        <label for={id} class={classListBody()}>
          <ChipBody icon={props.icon} img={props.image}>
            {props.children}
          </ChipBody>
        </label>
      </span>
    );
  } else if (props.type === "link" && (props.disabled ?? false)) {
    return (
      <span class={fullClassList()}>
        <span class={classListBody()}>
          <ChipBody icon={props.icon} img={props.image}>
            {props.children}
          </ChipBody>
        </span>
      </span>
    );
  } else if (props.type === "link") {
    return (
      <span class={fullClassList()}>
        <a href={props.href} class={classListBody()}>
          <ChipBody icon={props.icon} img={props.image}>
            {props.children}
          </ChipBody>
        </a>
      </span>
    );
  }
  return (
    <span class={fullClassList()}>
      <span class={classListBody()}>
        <ChipBody icon={props.icon} img={props.image}>
          {props.children}
        </ChipBody>
      </span>
      {props.type === "removable" ? (
        <button type="button" class="chip-close" aria-label="Close" disabled={disabled}>
          <i class="ico-close" aria-hidden="true"></i>
        </button>
      ) : null}
    </span>
  );
});
