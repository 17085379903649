import TomSelect from "@remotedevforce/tom-select";
import "/src/bootstrap-wrapper/_tom-select.scss";
import type { RecursivePartial, TomSettings } from "@remotedevforce/tom-select/dist/types/types";
import { assert, assertIsString, hasProp } from "@utils/assertion";
import { registerModule } from "@/Registry";
import { isNonNullObject } from "@utils/objectUtils";

type RenderFunction = (data: unknown, escape: (str: string) => string) => string;

function getRenderItemOptionFunction(type: "block" | "pebble"): RenderFunction {
  return (data: unknown, escape: (str: string) => string): string => {
    assert(isNonNullObject(data) && hasProp(data, "text"), "text not found on data");
    assertIsString(data.text, "text is not a string");
    if (hasProp(data, "gaFormFieldSelectSrc") && typeof data.gaFormFieldSelectSrc === "string") {
      return `<div class="d-flex gap-2"><div><img src="${escape(data.gaFormFieldSelectSrc)}" alt="" style="max-height: ${type === "block" ? "1.375rem;" : "1rem;"}"></div><div>${escape(data.text)}</div></div>`;
    } else {
      return `<div>${escape(data.text)}</div>`;
    }
  };
}

registerModule("[data-ga-form-field-select]", (el) => {
  if (!(el instanceof HTMLSelectElement)) {
    return;
  }
  const searchable = el.dataset.gaFormFieldSelectSearchable === "true";
  const noResultsLabel = el.dataset.gaFormFieldSelectNoResultsLabel ?? "Remove";
  const removeLabel = el.dataset.gaFormFieldSelectRemoveLabel ?? "No results found.";

  const options: RecursivePartial<TomSettings> = { maxOptions: Number.MAX_SAFE_INTEGER };
  options.render = {};
  options.plugins = {
    change_listener: {},
  };
  if (el.multiple) {
    options.plugins["remove_button"] = { title: removeLabel };
  }
  if (searchable) {
    options.plugins["dropdown_input"] = {};
    options.render["no_results"] = () => '<div class="no-results">' + noResultsLabel + "</div>";
    options.controlInput = '<input type="text" autocomplete="off" size="1" data-ga-validator-ignore="">';
  } else {
    options.controlInput = '<input type="text" autocomplete="off" size="1" data-ga-validator-ignore="" readonly>';
  }
  if (Array.from(el.options).some((i) => i.value === "")) {
    options.plugins["clear_button"] = {}; // If there is an empty value item, allow clearing the selection, even if that placeholder item is not selectable normally
  }
  options.render["option"] = getRenderItemOptionFunction("block");
  options.render["item"] = getRenderItemOptionFunction(el.multiple ? "pebble" : "block");

  const ts = new TomSelect(el, options);
  ts.on("blur", () => el.dispatchEvent(new Event("blur"))); // Trigger native blur event on select, as this is used in ga-validator.js
});
