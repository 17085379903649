import { type Ref, ref, type UnwrapRef } from "vue";
import { dictToList } from "@utils/dictUtils";

type Fn = (...args: any[]) => unknown;

type GetAllParamsAfterFirst<TFirst, T extends (first: TFirst, ...rest: any[]) => unknown> = T extends (first: TFirst, ...rest: infer P) => unknown ? P : never;

// Removes first param on all properties, remembers return type
type ExtractGetters<TFirst, T extends Record<string, Fn>> = {
  [P in keyof T]: (...args: GetAllParamsAfterFirst<TFirst, T[P]>) => ReturnType<T[P]>;
};

// Removes first param on all properties, all functions will return void
type ExtractActions<TFirst, T extends Record<string, Fn>> = {
  [P in keyof T]: (...args: GetAllParamsAfterFirst<TFirst, T[P]>) => void;
};

export type Store<UnwrappedState, ExtractedGetters = {}, ExtractedActions = {}> = {
  state: Ref<UnwrappedState>;
  update: (updateFn: (state: UnwrappedState) => UnwrappedState) => void;
  getters: ExtractedGetters;
  actions: ExtractedActions;
};

export function createStore<State, Getters extends Record<string, (a: UnwrapRef<State>, ...args: any[]) => unknown>, Actions extends Record<string, (a: UnwrapRef<State>, ...args: any[]) => UnwrapRef<State>>>(setup: {
  state: () => State;
  getters?: Getters;
  actions?: Actions;
}): Store<UnwrapRef<State>, ExtractGetters<UnwrapRef<State>, Getters>, ExtractActions<UnwrapRef<State>, Actions>> {
  const state = ref<State>(setup.state());

  const transformedGetters: Record<string, (...args: unknown[]) => unknown> = {};
  dictToList(setup.getters ?? {}).forEach(([name, fn]) => {
    transformedGetters[name] = (...args: unknown[]) => fn(state.value, ...args);
  });

  const transformedActions: Record<string, (...args: unknown[]) => void> = {};
  dictToList(setup.actions ?? {}).forEach(([name, fn]) => {
    transformedActions[name] = (...args: unknown[]) => {
      state.value = fn(state.value, ...args);
    };
  });

  return {
    state,
    update(updateFn) {
      state.value = updateFn(state.value);
    },
    getters: transformedGetters as ExtractGetters<UnwrapRef<State>, Getters>,
    actions: transformedActions as ExtractActions<UnwrapRef<State>, Actions>,
  };
}
